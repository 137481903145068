<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <div class="title-wrap">
              <h1 class="title-1">
                {{ $t('myAbsences') }}
              </h1>
            </div>
            <AbsencesTable
              :email="regularUser"
              :display-name="regularUser"
              @updateAbsences="updateAbsences"
              @updateAlert="updateAlert"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AbsencesTable from '@/components/common/AbsencesTable'
import OkkuApi from '@/services/OkkuApi'

export default {
  name: 'EditAbsencesPage',
  components: {
    AbsencesTable
  },
  data() {
    return {
      regularUser: this.$route.params.username,
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: ''
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },

    async updateAbsences(absences) {
      await OkkuApi.updateAbsences(absences)
      this.updateAlert({
        variant: 'success',
        text: this.$t('changesSaved')
      })
    }
  }
}
</script>
